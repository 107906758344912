/**
 * 品牌排序
 * @type {*[]}
 */
export const brandSort = [
    {
        value:'Level A',
        label:1
    },
    {
        value:'Level B',
        label:2
    },
    {
        value:'Level C',
        label:3
    }
]
