import request from '@/utils/request-utils'
import { appConfig } from '@/config/app-config';
const evnUrl = appConfig.domain.httpUrl
export * from './enum'

/**
 * get 拉取首字母品牌列表
 * @param params
 * @returns {Promise<*>}
 */
export const getBrandLetterService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/brand/category/letter'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * get 根据品牌id获取信息
 * @param params
 * @returns {Promise<*>}
 */
export const getBrandInfoService = async (params) => {
    try {
        const { data } = await request.get(evnUrl.concat('/api/brand/id?id='+params.id))
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}

/**
 * post 编辑品牌
 * @param params
 * @returns {Promise<*>}
 */
export const getBrandSaveService = async (params) => {
    try {
        const { data } = await request.postJson(evnUrl.concat('/api/brand'),params)
        return data
    } catch (error) {
        return Promise.reject(error)
    }
}
