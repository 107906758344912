<template>
    <div class="layout-body">
        <!--s: Header-->
        <Header/>
        <!--e: Header-->

        <!--s: Type-->
        <div class="product-type">
            <div class="type-back"><a href="javascript:" @click="onBack">Back</a></div>
        </div>
        <!--e: Type-->

        <!--s: Brand -->
        <div class="brand-body"  v-if="editObj.en">

            <!--s: Title-->
            <div class="brand-title">
                <span class="name">{{ brandId === ''?'New':editObj.en.name }}</span>
            </div>
            <!--e: Title-->

            <!--s: Content-->
            <div class="brand-edit">
                <div class="edit-check edit-border-bottom">
                    <div class="box">
                        <ul>
                            <li :key="key" v-for="(lang,key) in language">
                                <i class="label-icon" :class="lang.checkBox?'select':'selected'"></i>
                                <span class="label-name" :class="lang.checkBox?'title':''">{{ lang.name }}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="brand-name">
                    <ul>
                        <li>
                            <div class="title">
                              <span class="name store">Brand name</span>
                            </div>
                            <div class="input-box">
                                <input type="text" v-model="editObj.en.name" placeholder="Brand name" class="input"/>
                            </div>
                        </li>
                        <li>
                            <div class="label" :key="key" v-for="(brand,key) in brandSort" @click="onSelectLevel(brand.label)">
                                <i class="label-icon" :class="editObj.en.level === brand.label?'selected':'select'"></i>
                                <span class="label-name">{{ brand.value }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="brand-type">
                    <div class="box" :key="'bc'+key" v-for="(brandType,key) in editObj.en.bcList">
                        <div class="list">
                            <ul>
                                <li class="name">
                                    <div class="label" @click="onBrandTypeAll(brandType,key)">
                                        <i class="label-icon" :class="brandType.check === 1?'selected':'select'"></i>
                                        <span class="label-name">{{ brandType.name }}</span>
                                    </div>
                                </li>
                                <li :key="'category'+index" v-for="(category,index) in brandType.categoryList">
                                    <div class="label"  @click="onBrandTypeSelect(category,key,index)">
                                        <i class="label-icon" :class="category.check === 1 ?'select':'selected'"></i>
                                        <span class="label-name">{{ category.name }}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="brand-upload">
                            <span class="title">Cover image - 545 x 325</span>
                            <div class="upload">
                                <span class="value">{{ brandType.img }}</span>
                                <Button>Upload<input type="file" class="input"  @change="onImgDataChange($event,key)"/></Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="brand-terminal">
                    <a href="javascript:"  :class="editObj.en.osType === item.id?'active':''" :key="key" v-for="(item,key) in terminal" @click="onTerminal(item.id)">{{ item.name }}</a>
                </div>
                <div class="brand-plug">
                    <StyleDown v-model="editObj.en.cSize" disabled  title="Size" :width="70" :margin-right="25" :data="sizeSubList" />
                    <StyleDown v-model="editObj.en.cLeading" disabled  title="Leading" :width="70" :margin-right="25" :data="leadingList"/>
                    <StyleDown v-model="editObj.en.cLocation" disabled  title="Location" :width="70" :margin-right="25" :data="locationList"/>
                    <StyleDown v-model="editObj.en.cColour" title="Colour" color :width="70" :margin-right="25" :data="colorList"/>
                </div>
                <div class="brand-text">
                    <div class="box margin-right-30">
                        <div class="title">
                            <span style="float:left;">Introduction</span>
                            <span style="float:right;">Montserrat - Regular</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.en.content" type="textarea" :rows="4"></Input>
                        </div>
                    </div>
                    <div class="box" >
                        <div class="title">
                            <span style="float:left;">品牌介绍</span>
                            <span style="float:right;">方正兰亭 - 细黑 / Montserrat - Regular</span>
                        </div>
                        <div class="text">
                            <Input v-model="editObj.cn.content" type="textarea" :rows="4"></Input>
                        </div>
                    </div>
                </div>
                <!--s: Btn-->
                <div class="brand-btn-edit">
                    <div class="box">
                        <Button class="margin-right-15" v-if="brandId !== ''" :loading="loadingStatus" @click="onEditShutSubmit">Shut</Button>
                        <Button class="margin-right-30" :loading="loadingStatus" @click="onEditOnSubmit">On</Button>
                        <Button :loading="loadingStatus" @click="onEditSubmit">Save</Button>
                    </div>
                </div>
                <!--s: Btn-->
            </div>
            <!--e: Content-->

        </div>
        <!--e: Brand -->

    </div>
</template>
<script>
import BrandEdit from './edit'
export default BrandEdit
</script>
<style lang="less">
@import "edit";
</style>
