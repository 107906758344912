/**
 * 品牌编辑
 */
import Vue from 'vue'
import { Icon, Input, Button, Message } from "iview";
Vue.component('Icon', Icon);
Vue.component('Input', Input);
Vue.component('Button', Button);

import Header from '@/components/Header/header.vue'
import { mapMutations, mapState } from "vuex";
import {
    addProductDataService,
    colorList,
    language,
    leadingList,
    locationList,
    sizeSubList,
    terminal
} from "@/service/marketing-service";
import { brandSort,getBrandInfoService,getBrandSaveService } from "@/service/brand-service"
import StyleDown from "@/components/StyleDown/styleDown.vue";
const BrandEdit = {
    name:'BrandEdit',
    components:{
        Header,
        StyleDown
    },
    computed: {
        ...mapState({
            brandId: state => state.brand.brandId,  //获取状态管理品牌id
        }),
    },
    data() {
        return {
            loadingStatus:false,
            language:language,//语言
            terminal:terminal, //终端
            brandSort:brandSort, //品牌排序数据
            sizeSubList:sizeSubList, //副标题文字大小
            leadingList:leadingList, //间距大小
            locationList:locationList, //位置
            colorList:colorList, //颜色
            editObj:{}, //编辑数据集
        }
    },
    created() {
        this.onBrandInfo().then()
    },
    methods: {
        ...mapMutations('brand',['SET_BRAND_ID']),
        /**
         * 返回至列表页
         */
        onBack() {
            this.$router.back()
        },
        /**
         * 选择品牌排序
         * @param brand
         */
        onSelectLevel(label){
            this.editObj.en.level = label
            this.editObj.cn.level = label
        },
        /**
         * 选择终端
         * @param id
         */
        onTerminal(id){
            this.editObj.en.osType = id
            this.editObj.cn.osType = id
        },
        /**
         * 分类全选
         * @param brandType
         */
        onBrandTypeAll(brandType,key){
            if(this.onFilter(brandType.categoryList)){
                for(let i in brandType.categoryList) {
                    brandType.categoryList[i].check = 0
                }
                this.editObj.en.bcList[key].check = 0
            }else{
                for(let i in brandType.categoryList) {
                    brandType.categoryList[i].check = 1
                }
                this.editObj.en.bcList[key].check = 1
            }

        },
        /**
         * 全选/取消 过滤器
         * @param list
         */
        onFilter(list){
            let bool = false;
            for(let i in list){
                if(list[i].check === 1){
                    bool = true
                }else{
                    bool = false
                    return
                }
            }
            return bool
        },
        /**
         * 分类选中
         * @param category
         * @param key
         * @param index
         */
        onBrandTypeSelect(category,key,index) {
            let check = this.editObj.en.bcList[key].categoryList[index].check
            check === 0 ? this.editObj.en.bcList[key].categoryList[index].check = 1 : this.editObj.en.bcList[key].categoryList[index].check = 0
            if(this.onFilter(this.editObj.en.bcList[key].categoryList)){
                this.editObj.en.bcList[key].check = 1
            }else{
                this.editObj.en.bcList[key].check = 0
            }
        },
        /**
         * 上传图片文件
         * @param event
         * @param n
         */
        onImgDataChange(event,key){
            const file =  event.target.files[0];
            if (file.size > 5 * 1024 * 1024) {
                Message.error('请选择5M以内的图片')
                return
            }
            this.onAddImageProductData(file,key).then()
        },
        /**
         * 关闭
         */
        onEditShutSubmit(){
            if(this.editObj.en.name === ''){
                Message.error('请输入品牌名称！')
                return;
            }
            this.editObj.en.status = 0
            this.onBrandSave().then()
        },
        /**
         * 打开
         */
        onEditOnSubmit(){
            if(this.editObj.en.name === ''){
                Message.error('请输入品牌名称！')
                return;
            }
            this.editObj.en.status = 1
            this.onBrandSave().then()
        },
        /**
         * 提交数据
         */
        onEditSubmit() {
            if(this.editObj.en.name === ''){
                Message.error('请输入品牌名称！')
                return;
            }
            this.onBrandSave().then()
        },
        /**
         * 根据品牌id获取信息
         * @returns {Promise<void>}
         */
        async onBrandInfo() {
            try {
                let params = {
                    id:this.brandId
                }
                const {code,data} = await getBrandInfoService(params)
                if(code === 1){
                    for(let i in data.en.bcList){
                        if(this.onFilter(data.en.bcList[i].categoryList)){
                            data.en.bcList[i].check = 1
                        }else{
                            data.en.bcList[i].check = 0
                        }
                    }
                    this.editObj = data
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 上传图片文件
         */
        async onAddImageProductData(files,key) {
            try {
                let params =  new FormData()
                params.append('file',files)

                const { code,data } = await addProductDataService(params)
                if(code === 1){
                    Message.success('Success！')
                    this.editObj.en.bcList[key].img = data.path
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 编辑品牌
         * @returns {Promise<void>}
         */
        async onBrandSave() {
            try {
                this.loadingStatus = true
                let params = this.editObj
                const { code } = await getBrandSaveService(params)
                if(code === 1){
                    this.$router.push('/brand/list')
                    this.SET_BRAND_ID([])
                }
                this.loadingStatus = false
            }catch (error){
                this.loadingStatus = false
                console.log(error)
            }
        },
    },
}
export default BrandEdit
